<template>
  <div>
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/my-club/'"
                style="text-decoration: none;"
              >
                <v-btn rounded color="#05a2fa">
                  <v-icon color="white">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
          <v-col cols="14" sm="8" md="4">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn rounded color="#05a2fa" dark @click="dialog = true">
                  <v-icon color="white">
                    mdi-plus-circle
                  </v-icon>
                  <v-card-text> Add a New Asset Type </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          style="margin-bottom:10px; margin-top:-35px;"
        >
        </v-text-field>
        <v-row>
          <v-col cols="14" sm="8" md="12" class="itemsLayout">
            <v-row dense>
              <v-col cols="2" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="#05a2fa"
                      style="font-size:70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-for="card in newItems"
                :key="card.assetTypeId"
                :cols="2"
                class="pb-3"
              >
                <router-link
                  v-bind:to="{
                    path: '/assets-type/' + card.assetTypeId
                  }"
                  style="text-decoration: none;"
                >
                  <v-card height="200" width="150" style="cursor:pointer">
                    <v-img
                      :src="`https://cdn.vuetifyjs.com/images/cards/road.jpg`"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      width="150"
                      height="100"
                    >
                      <v-card-title style="height:40px"
                        >Type Id : {{ card.assetTypeId }}
                      </v-card-title>
                    </v-img>

                    <v-card-text>
                      {{ card.assetTypeName }}
                    </v-card-text>
                  </v-card>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="14" sm="8" md="12" style="margin-top: -25px;">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @click="changePage"
              circle
              color="#05a2fa"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>

      <v-container @click="dialogTrash = true" style="cursor:pointer">
        <v-row>
          <v-col cols="14" sm="8" md="12">
            <v-card>
              <v-card-text class="text-center" style="font-size:15px">
                <v-icon color="#05a2fa" style="font-size:25px">
                  mdi-delete-empty
                </v-icon>
                Trash
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Asset Type</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="typesForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.assetTypeName"
                      label="Asset Type Name"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.rent" label="Rent" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.ean" label="EAN" />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.assetCategoryId"
                      :items="assetCategories"
                      item-text="assetCategoryId"
                      item-value="assetCategoryId"
                      label="Asset Category Id"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>

          <v-data-table :headers="headers" :items="trashed" :search="search">
            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="#05a2fa" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="deleteItem(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to restore this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogRestore = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.row1 {
  margin-top: -25px;
}
.itemsLayout {
  height: 450px;
  overflow: hidden;
  overflow-y: auto;
}
.addbutton {
  float: right;
}
.temp {
  background-color: rgb(238, 238, 238);
}
</style>
<script>
import MaterialCard from "../components/MaterialCard.vue";
var moment = require("moment");

export default {
  components: { MaterialCard },
  data() {
    return {
      dialogTrash: false,
      dialog: false,
      dialogDelete: false,
      dialogRestore: false,
      dateModal: false,
      search: "",

      headers: [
        { text: "assetTypeId", value: "assetTypeId" },
        { text: "assetTypeName", value: "assetTypeName" },
        { text: "description", value: "description" },
        { text: "rent", value: "rent" },
        { text: "ean", value: "ean" },
        { text: "assetCategoryId", value: "assetCategoryId" },
        { text: "", value: "restore" },
        { text: "", value: "delete" }
      ],
      types: [],

      editedIndex: -1,
      editedItem: {
        assetTypeName: "",
        description: "",
        rent: "",
        ean: "",
        assetCategoryId: ""
      },
      defaultItem: {
        assetTypeName: "",
        description: "",
        rent: "",
        ean: "",
        assetCategoryId: ""
      },
      trashed: [],
      assetCategories: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 11,
      newItems: []
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add a New Product" : "Edit Product";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function() {
      this.changePage();
    }
  },

  created() {
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      this.$http.get("/AssetType").then(response => {
        this.types = response.data;
        this.changePage();
      });
      this.$http.get("/AssetCategory").then(response => {
        this.assetCategories = response.data;
      });
      this.$http.get(`/AssetType/GetTrashed`).then(response => {
        this.trashed = response.data.data;
      });
    },
    changePage() {
      var count = this.types.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.types.slice(newStart, newEnd);
      this.newItems = items;
    },
    editItem(item) {
      //this.editedIndex = this.users.indexOf(item);
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      // this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    restoreItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    restoreItemConfm() {
      var id = this.editedItem.assetTypeId
      console.log(id,this.editedItem)
      this.$http
        .post(`/AssetType/Restore?Id=${id}`)
        .then(response => {
          if (response.data.success === true) {
            this.getDataFromApi();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight"
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight"
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      var Id = this.editedItem.productId;
      this.$http
        .delete("/Product/" + Id, null, {
          params: { Id: this.editedItem.id }
        })
        .then(response => {
          if (response.status === 200) {
            this.$toast.success(
              "Product deleted successfully." + response.status,
              "Success",
              {
                position: "topRight"
              }
            );
          }
          //this.products.shift(response.data.data);
        })

        .catch(error => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        });
      //this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.typesForm.validate()) {
        this.$http
          .post("/AssetType", this.editedItem)
          .then(response => {
            if (response.data.isSuccess === true) {
              this.getDataFromApi();
              this.$toast.success("Asset Type added successfully.", "Success", {
                position: "topRight"
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast.error("Adding Process Failed", "Error", {
                position: "topRight"
              });
            }
          });

        this.close();
      }
    },
    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    }
  }
};
</script>
